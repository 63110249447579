
















import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'throttle-debounce';
import { useAlerts } from '~/features/alerts';
import { useAuth } from '~/features/auth';
import { useEventBus } from '~/features/events';
import { useI18n } from '~/features/i18n';
import { ProductData } from '~/features/product';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';
import { useAddToWishlist, useIsInWishlist, useRemoveFromWishlist } from '~/features/wishlist';

export default defineComponent({
  name: 'FavoriteButton',
  props: {
    sku: {
      type: String,
      required: true,
    },
    product: {
      type: Object as PropType<ProductData>,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const isFavorited = ref(false);
    const { isLoggedIn } = useAuth();
    const { emit: emitEvent } = useEventBus();
    const { success, error } = useAlerts();
    const { t } = useI18n();

    if (isLoggedIn?.value) {
      const isInWishlist = useIsInWishlist(props.sku);

      watch(
        isInWishlist,
        value => {
          isFavorited.value = value;
        },
        {
          immediate: true,
        }
      );
    }

    const { addToWishlist } = useAddToWishlist(props.sku);
    const { removeFromWishlist } = useRemoveFromWishlist(props.sku);
    // Ensures we don't call too many requests
    const commitToggle = debounce(500, () => {
      if (isFavorited.value) {
        props.product && emitEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, props.product);

        return addToWishlist().then(({ data, error: addToWishlistError }) => {
          if (addToWishlistError || data?.response?.errors.length) {
            error(t('productNotAddedToWishlist').toString());
          } else {
            success(t('productAddedToWishlist').toString());
          }
        });
      }

      return removeFromWishlist().then(() => {
        props.product && emitEvent(TRACKING_EVENTS.REMOVED_FROM_WISHLIST, props.product);
        emit('unfavorite');
      });
    });

    function toggleWishlist() {
      if (!isLoggedIn?.value) {
        emitEvent('AUTH_REQUIRED');
        return;
      }

      isFavorited.value = !isFavorited.value;

      commitToggle();
    }

    return {
      isFavorited,
      toggleWishlist,
    };
  },
});
