import { computed, inject, reactive, ref, unref, useContext, watch } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { mapProductListing } from './products';
import { useGetEliteInfo } from './elite';
import {
  AddToWishlistMutation,
  AddToWishlistDocument,
  AllWishlistItemsDocument,
  RemoveFromWishlistDocument,
  WishlistCountDocument,
  WishlistDocument,
  WishlistQuery,
  WishlistQueryVariables,
} from '~/graphql/Wishlist';
import { MaybeReactive } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { ProductCardFragment } from '~/graphql/fragments';
import { AUTH_USER } from '~/utils/provides';

const WISHLIST_TRACKER = reactive({
  count: 0,
  lookup: {} as Record<string, number>,
  initialized: false,
});

export function useWishlist(variables?: MaybeReactive<WishlistQueryVariables>) {
  const items = ref<ReturnType<typeof mapWishlistItems>>([]);
  const { attachedCorporate } = useGetEliteInfo();

  const { data, error, isFetching, execute } = useQuery({
    query: WishlistDocument,
    variables: {
      ...variables,
      corporateId: attachedCorporate?.value?.id,
    },
    cachePolicy: 'network-only',
  });

  watch(data, value => {
    if (!value) {
      return;
    }

    const vars = unref(variables);
    if (vars?.currentPage === 1) {
      items.value = mapWishlistItems(value);
      return;
    }

    items.value.push(...mapWishlistItems(value));
  });

  const totalCount = computed(() => {
    return data.value?.customer?.wishlist.totalCount || 0;
  });

  return {
    data,
    items,
    totalCount,
    error,
    isFetching,
    refetch: execute,
  };
}

function mapWishlistItems(data: WishlistQuery) {
  if (!data) {
    return [];
  }

  return toNonNullable(data.customer?.wishlist?.items_v2?.items).map(item => ({
    wishlistId: item.id,
    ...mapProductListing(item.product as ProductCardFragment),
  }));
}

export function useAddToWishlist(sku: string) {
  const user = inject(AUTH_USER);
  const { execute } = useMutation(AddToWishlistDocument);
  const { app } = useContext();

  async function addToWishlist() {
    const { data, error } = await execute({
      pageSize: WISHLIST_TRACKER.count + 100, // keep a buffer of more items
      wishlistId: user?.value.wishlists[0]?.id as string,
      wishlistItems: [
        {
          sku,
          quantity: 1,
        },
      ],
    });

    if (error) {
      throw error;
    }

    // TODO: Handle errors
    if (data.response?.errors.length) {
      throw new Error(data.response.errors[0]?.message);
    }

    // Fire Facebook event on adding to wishlist
    if (typeof app.$fireFbEvent === 'function') {
      app.$fireFbEvent('AddToWishlist');
    }

    updateTracker(data?.response?.wishlist);
    return {
      data,
      error,
    };
  }

  return {
    addToWishlist,
  };
}

export function useRemoveFromWishlist(sku: string) {
  const user = inject(AUTH_USER);
  const { execute } = useMutation(RemoveFromWishlistDocument);

  async function removeFromWishlist() {
    const { data, error } = await execute({
      pageSize: WISHLIST_TRACKER.count + 100, // keep a buffer of more items
      wishlistId: user?.value.wishlists[0]?.id as string,
      wishlistItemsIds: [String(WISHLIST_TRACKER.lookup[sku])],
    });

    if (error) {
      throw error;
    }

    // TODO: Handle errors
    if (data.response?.errors.length) {
      throw new Error(data.response.errors[0]?.message);
    }

    updateTracker(data?.response?.wishlist);
    return {
      data,
      error,
    };
  }

  return {
    removeFromWishlist,
  };
}

function useInitWishlistTracker() {
  WISHLIST_TRACKER.initialized = true;
  const { data: countData } = useQuery({
    query: WishlistCountDocument,
    cachePolicy: 'network-only',
  });

  const { data } = useQuery({
    query: AllWishlistItemsDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
    variables: computed(() => {
      if (!countData.value) {
        return {};
      }

      return {
        pageSize: countData.value?.customer?.wishlist.totalCount || 100,
      };
    }),
  });

  watch(data, value => {
    updateTracker(value?.customer?.wishlist);
  });
}

export function useIsInWishlist(sku: string) {
  if (!WISHLIST_TRACKER.initialized) {
    useInitWishlistTracker();
  }

  return computed(() => {
    return WISHLIST_TRACKER.lookup[sku] !== undefined;
  });
}

type WishlistPartial = NonNullable<AddToWishlistMutation['response']>['wishlist'];

function updateTracker(data: WishlistPartial | undefined) {
  WISHLIST_TRACKER.count = data?.totalCount || 100;
  WISHLIST_TRACKER.lookup =
    data?.items_v2?.items?.reduce((acc: Record<string, number>, item) => {
      const sku = item?.product?.sku;
      if (!sku || !item?.id) {
        return acc;
      }

      acc[sku] = Number(item?.id);

      return acc;
    }, {} as Record<string, number>) || {};
}
